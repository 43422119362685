import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { TextLink } from '@octane/spark';
import copy from '../copy';

import styles from './type-selector.module.scss';

import { DynamicIcon } from '../../../utils/icon-utils';

const ICON_SIZE = 62;
const MIN_TYPES_TO_SHOW = 6;

const TypeSelector = ({ vehicleTypes, selected, onChange }) => {
    const [openTypeSelector, setOpenTypeSelector] = useState(false);
    const [typeIdSelected, setTypeIdSelected] = useState(
        selected && selected.id
    );

    const types = openTypeSelector
        ? vehicleTypes
        : vehicleTypes.slice(0, MIN_TYPES_TO_SHOW);

    const gridClasses = classnames(styles.grid, 'spark-text-karla', {
        [styles.singleItem]: types?.length === 1,
    });

    const getItemClasses = (type) => {
        return classnames(styles.item, 'spark-shadow-tile', {
            [styles.selected]: type.id === typeIdSelected,
            [styles.notSelected]: typeIdSelected && type.id !== typeIdSelected,
        });
    };

    const handleSelectType = (type) => {
        if (type.id !== typeIdSelected) {
            setTypeIdSelected(type.id);
            onChange && onChange(type);
        }
    };

    const showExpander = vehicleTypes.length > MIN_TYPES_TO_SHOW;

    return (
        <div className={styles.typesContainer}>
            <div className={gridClasses} role="list">
                {types.map((type) => (
                    <div
                        id={`vtype-${type.id}`}
                        role="listitem"
                        key={type.id}
                        className={getItemClasses(type)}
                        onClick={() => handleSelectType(type)}
                    >
                        <DynamicIcon iconName={type.icon} width={ICON_SIZE} height={ICON_SIZE} />
                        <span>{type.name}</span>
                    </div>
                ))}
            </div>

            {/* Text Show More... */}
            {showExpander && (
                <div
                    id="vtype-expander"
                    className={styles.action}
                    onClick={() => setOpenTypeSelector(!openTypeSelector)}
                >
                    <TextLink size="medium" variant="underlined" as="button" type="button">
                        {!openTypeSelector && copy.expander.openLabel}
                        {openTypeSelector && copy.expander.closeLabel}
                    </TextLink>
                </div>
            )}
        </div>
    );
};

TypeSelector.propTypes = {
    selected: PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
    }),
    vehicleTypes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
};

export default TypeSelector;
